@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@font-face {
  font-family: 'Radikal';
  src:
    local('Radikal'),
    url(./fonts/Radikal.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-body;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hey__modal {
  max-width: 95%;
  animation: none !important;
}

@media (min-width: 1000px) {
  .hey__modal {
    max-width: 1000px;
    left: 50% !important;
    transform: translate(-50%, 0);
  }
}

h1,
h2,
h3 {
  @apply font-radikal;
}

h1 {
  @apply text-h1 font-radikal text-swopa-primary-dark-blue;
}

h2 {
  @apply text-h2 font-radikal text-swopa-primary-dark-blue;
}

h3 {
  @apply text-h3 font-radikal text-swopa-primary-dark-blue;
}

@media (max-width: 1280px) {
  h1 {
    @apply text-h1-mobile;
  }
  h2 {
    @apply text-h2-mobile;
  }
}

p > a {
  @apply underline;
}

/* Date picker styles for ios safari */
#date_of_birth:before {
  color: #898d8d;
  content: attr(placeholder);
}

#date_of_birth::-webkit-date-and-time-value {
  text-align: left;
}

.notification-banner a {
  margin-left: 4px;
  text-decoration: underline;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.appointmentBookingConfirmFooter {
  color: #333333;
  box-shadow: 0 0 1.5em .25em rgba(0, 0, 0, .075);
  background-color: #ffffff;
  -webkit-box-align: center;
}

.safari-scroll {
  -webkit-overflow-scrolling: touch;
}

